#header {
  .inner {
    padding-top: 45px;
    padding-bottom: 20px;
    border-bottom: 1px solid $grey;
    overflow: hidden;
    @media #{$mediaMobile} {
      padding: 15px 12px;
    }
    .logo-text {
      font-family: 'Avenir';
      text-transform: uppercase;
      text-decoration: none;
      font-size: 0;
      h1,
      h2 {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
      }
      h1 {
        font-weight: 300;
        font-size: 61px;
        line-height: 50px;
        color: $blue;
        @media #{$mediaMobile} {
          font-size: 33px;
          line-height: 28px;
        }
      }
      h2 {
        font-weight: 900;
        font-size: 25px;
        //line-height: 25px;
        line-height: 1;
        color: $green;
        margin-left: 5px;
        @media #{$mediaMobile} {
          font-size: 14px;
          //line-height: 14px;
        }
      }
    }
    .logo-img {
      float: right;
      @media #{$mediaMobile} {
        display: none;
      }
    }
  }
  @media #{$mediaMobile} {
    height: 59px;
    .inner {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      z-index: 50;
    }
  }
}