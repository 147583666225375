@mixin fontFace($font-name, $font-weight: normal){
  @font-face {
    font-family: "#{$font-name}";
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot");
    src: url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.eot?#iefix")format("embedded-opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff") format("woff"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.woff2") format("woff2"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.otf") format("opentype"),
    url("../fonts/#{$font-name}/#{$font-name}-#{$font-weight}.svg") format("SVG");
    font-style: normal;
    font-weight: $font-weight;
  }
}