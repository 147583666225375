// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-logo-s-n-c-f-name: 'sprite-Logo-SNCF';
$sprite-logo-s-n-c-f-x: 0px;
$sprite-logo-s-n-c-f-y: 0px;
$sprite-logo-s-n-c-f-offset-x: 0px;
$sprite-logo-s-n-c-f-offset-y: 0px;
$sprite-logo-s-n-c-f-width: 36px;
$sprite-logo-s-n-c-f-height: 19px;
$sprite-logo-s-n-c-f-total-width: 48px;
$sprite-logo-s-n-c-f-total-height: 41px;
$sprite-logo-s-n-c-f-image: '../img/sprite.png';
$sprite-logo-s-n-c-f: (0px, 0px, 0px, 0px, 36px, 19px, 48px, 41px, '../img/sprite.png', 'sprite-Logo-SNCF', );
$sprite-checkbox-checked-name: 'sprite-checkbox-checked';
$sprite-checkbox-checked-x: 12px;
$sprite-checkbox-checked-y: 29px;
$sprite-checkbox-checked-offset-x: -12px;
$sprite-checkbox-checked-offset-y: -29px;
$sprite-checkbox-checked-width: 13px;
$sprite-checkbox-checked-height: 11px;
$sprite-checkbox-checked-total-width: 48px;
$sprite-checkbox-checked-total-height: 41px;
$sprite-checkbox-checked-image: '../img/sprite.png';
$sprite-checkbox-checked: (12px, 29px, -12px, -29px, 13px, 11px, 48px, 41px, '../img/sprite.png', 'sprite-checkbox-checked', );
$sprite-checkbox-name: 'sprite-checkbox';
$sprite-checkbox-x: 25px;
$sprite-checkbox-y: 29px;
$sprite-checkbox-offset-x: -25px;
$sprite-checkbox-offset-y: -29px;
$sprite-checkbox-width: 11px;
$sprite-checkbox-height: 11px;
$sprite-checkbox-total-width: 48px;
$sprite-checkbox-total-height: 41px;
$sprite-checkbox-image: '../img/sprite.png';
$sprite-checkbox: (25px, 29px, -25px, -29px, 11px, 11px, 48px, 41px, '../img/sprite.png', 'sprite-checkbox', );
$sprite-close-blue-name: 'sprite-close-blue';
$sprite-close-blue-x: 36px;
$sprite-close-blue-y: 0px;
$sprite-close-blue-offset-x: -36px;
$sprite-close-blue-offset-y: 0px;
$sprite-close-blue-width: 12px;
$sprite-close-blue-height: 12px;
$sprite-close-blue-total-width: 48px;
$sprite-close-blue-total-height: 41px;
$sprite-close-blue-image: '../img/sprite.png';
$sprite-close-blue: (36px, 0px, -36px, 0px, 12px, 12px, 48px, 41px, '../img/sprite.png', 'sprite-close-blue', );
$sprite-close-green-name: 'sprite-close-green';
$sprite-close-green-x: 36px;
$sprite-close-green-y: 12px;
$sprite-close-green-offset-x: -36px;
$sprite-close-green-offset-y: -12px;
$sprite-close-green-width: 12px;
$sprite-close-green-height: 12px;
$sprite-close-green-total-width: 48px;
$sprite-close-green-total-height: 41px;
$sprite-close-green-image: '../img/sprite.png';
$sprite-close-green: (36px, 12px, -36px, -12px, 12px, 12px, 48px, 41px, '../img/sprite.png', 'sprite-close-green', );
$sprite-close-grey-name: 'sprite-close-grey';
$sprite-close-grey-x: 0px;
$sprite-close-grey-y: 29px;
$sprite-close-grey-offset-x: 0px;
$sprite-close-grey-offset-y: -29px;
$sprite-close-grey-width: 12px;
$sprite-close-grey-height: 12px;
$sprite-close-grey-total-width: 48px;
$sprite-close-grey-total-height: 41px;
$sprite-close-grey-image: '../img/sprite.png';
$sprite-close-grey: (0px, 29px, 0px, -29px, 12px, 12px, 48px, 41px, '../img/sprite.png', 'sprite-close-grey', );
$sprite-shape-name: 'sprite-shape';
$sprite-shape-x: 0px;
$sprite-shape-y: 19px;
$sprite-shape-offset-x: 0px;
$sprite-shape-offset-y: -19px;
$sprite-shape-width: 17px;
$sprite-shape-height: 10px;
$sprite-shape-total-width: 48px;
$sprite-shape-total-height: 41px;
$sprite-shape-image: '../img/sprite.png';
$sprite-shape: (0px, 19px, 0px, -19px, 17px, 10px, 48px, 41px, '../img/sprite.png', 'sprite-shape', );
$spritesheet-width: 48px;
$spritesheet-height: 41px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($sprite-logo-s-n-c-f, $sprite-checkbox-checked, $sprite-checkbox, $sprite-close-blue, $sprite-close-green, $sprite-close-grey, $sprite-shape, );
$spritesheet: (48px, 41px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
