$width: 968px;

$mobile: 768px;
$mediaMobile: 'screen and (max-width:#{$mobile})';

$blue: #0088ce;
$green: #7ab800;
$grey: #757477;

@include fontFace('Avenir',300);
@include fontFace('Avenir',900);

body {
  font-size: 12px;
  font-family: Arial, sans-serif;
}

.container {
  max-width: $width;
  min-width: 320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 44px;
  @media #{$mediaMobile} {
    padding: 0;
  }
}