.filter {
  padding: 18px 0;
  color: #9a9a9d;
  position: relative;
  @media #{$mediaMobile} {
    padding: 18px 12px;
  }
  h3 {
    display: inline-block;
    margin: 0 15px 0 0;
    text-transform: uppercase;
    font-size: 14px;
    span {
      display: none;
      font-weight: normal;
    }
    @media #{$mediaMobile} {
      font-size: 17px;
      margin: 0;
      width: 100%;
      cursor: pointer;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -7px;
        @include sprite($sprite-shape);
      }
      span {
        display: inline-block;
      }
      &.open {
        &:after {
          display: none;
        }
        & ~ div {
          display: block;
        }
      }
    }
  }
  .close-filter {
    @include sprite($sprite-close-grey);
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
    display: none;
  }
  .wrap {
    display: inline-block;
    @media #{$mediaMobile} {
      display: none;
      margin-top: 10px;
    }
  }
  .form-item {
    display: inline-block;
    @media #{$mediaMobile} {
      display: block;
      margin: 5px 0;
    }
    label {
      padding-left: 15px;
      margin-right: 15px;
      position: relative;
      &:before {
        content: '';
        @include sprite($sprite-checkbox);
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -6px;
      }
    }
    input {
      display: none;
      &:checked {
        & + label {
          &:before {
            @include sprite($sprite-checkbox-checked);
          }
        }
      }
    }
  }
}

.excerpt {
  margin-bottom: 30px;
  h3 {
    width: 100%;
    font-size: 14px;
    line-height: 15px;
    color: #fff;
    text-transform: uppercase;
    padding: 10px;
    box-sizing: border-box;
    margin: 0 0 10px;
    background-color: $grey;
    @media #{$mediaMobile} {
      font-size: 20px;
      line-height: 20px;
      padding: 15px 12px;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    @media #{$mediaMobile} {
      display: block;
      margin: 0;
    }
    &.active {
      .item {
        opacity: .5;
        @media #{$mediaMobile} {
          opacity: 1;
        }
      }
    }
    .description {
      order: 5;
      width: 100%;
      color: #3e3e40;
      border-right: 3px solid transparent;
      border-bottom: 3px solid transparent;
      position: relative;
      column-count: 2;
      column-gap: 40px;
      margin: 0 4px;
      //display: none;

      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      //transform: translateY(-50%) scaleY(0);
      height: 0;
      transition-duration: 1.2s;
      @media #{$mediaMobile} {
        border: none;
        padding: 5px 12px 12px;
        column-count: 1;
        column-gap: 0;
        margin: 0;
        box-sizing: border-box;
      }
      .close {
        position: absolute;
        top: 5px;
        right: 16px;
        cursor: pointer;
        @media #{$mediaMobile} {
          display: none;
        }
      }
      p {
        margin: 0 0 15px;
        line-height: 16px;
        &:first-child {
          margin-top: -5px;
          &:first-letter {
            font-size: 26px;
            line-height: 16px;
          }
        }
      }
    }
    .item {
      margin: 0 0 10px;
      padding: 0 4px;
      box-sizing: border-box;
      &.active,
      &:hover {
        .text {
          height: 200px;
          h2 {
            font-size: 32px!important;
            margin: 0;
          }
          p {
            //display: none;
            opacity: 0;
            height: 0;
            @media #{$mediaMobile} {
              opacity: 1;
              height: auto;
            }
          }
          @media #{$mediaMobile} {
            height: auto;
            h2 {
              font-size: 24px!important;
              margin: 0 0 10px;
            }
            p {
              display: block;
            }
          }
        }
      }
      &.active {
        opacity: 1;
        & + .description {
          //display: block;

          overflow: hidden;
          visibility: visible;
          opacity: 1;
          height: auto;
          //transform: translateY(0) scaleY(1);
          padding: 30px 38px 23px;
          text-align: justify;
          @media #{$mediaMobile} {
            padding: 10px 12px;
            margin-top: -10px;
          }
        }
        @media #{$mediaMobile} {
          .item-inner {
            background-color: transparent!important;
            .close {
              display: block!important;
            }
          }
          .text {
            p {
              display: none;
            }
            .inner-text {
              padding-bottom: 0;
            }
          }
        }
      }
      .item-inner {
        height: 335px;
        overflow: hidden;
        color: #fff;
        position: relative;
        cursor: pointer;
        @media #{$mediaMobile} {
          height: auto;
        }
      }
      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        //height: 100%;
        box-sizing: border-box;
        height: 125px;
        transition-duration: .5s;
        @media #{$mediaMobile} {
          position: static;
          height: auto;
          background-color: transparent!important;
        }
        .inner-text {
          padding: 15px 20px;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          @media #{$mediaMobile} {
            position: relative;
            transform: translateY(0);
            padding: 35px 12px;
            top: auto;
            left: 0;
            right: 0;
          }
          h2 {
            font-size: 22px;
            margin: 0 0 10px;
            transition-duration: .5s;
            @media #{$mediaMobile} {
              font-size: 24px;
              br {
                display: none;
              }
            }
          }
          p {
            margin: 0;
            transition-duration: .5s;
            @media #{$mediaMobile} {
              font-size: 16px;
            }
          }
          .close {
            display: none;
            width: 25px;
            height: 25px;
            position: absolute;
            right: 12px;
            top: 15px;
            cursor: pointer;
            &:before,
            &:after {
              content: '';
              width: 100%;
              height: 1px;
              position: absolute;
              top: 50%;
              left: 50%;
              background: #000;
              margin: 0 -10px;
            }
            &:before {
              transform: rotate(45deg);
            }
            &:after {
              transform: rotate(-45deg);
            }
          }
        }
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        min-width: 100%;
        max-height: 210px;
        width: auto;
        height: auto;
        transform: translateX(-50%);
        @media #{$mediaMobile} {
          position: static;
          transform: translateY(0);
          max-height: none;
          max-width: 100%;
        }
      }
      //Width
      &.td50 {
        min-width: 50%;
      }
      &.td25 {
        width: 25%;
      }
      //Color
      &.blue {
        .text {
          background-color: $blue;
        }
        .item-inner {
          background-color: $blue;
          .close {
            &:before,
            &:after {
              background-color: $blue;
            }
          }
        }
        & + .description {
          border-color: $blue;
          p {
            &:first-child {
              &:first-letter {
                color: $blue;
              }
            }
          }
          .close {
            @include sprite($sprite-close-blue);
          }
        }
        @media #{$mediaMobile} {
          &.active {
            .item-inner {
              h2 {
                color: $blue;
              }
            }
          }
        }
      }
      &.green {
        .text {
          background-color: $green;
        }
        .item-inner {
          background-color: $green;
          .close {
            &:before,
            &:after {
              background-color: $green;
            }
          }
        }
        & + .description {
          border-color: $green;
          p {
            &:first-child {
              &:first-letter {
                color: $green;
              }
            }
          }
          .close {
            @include sprite($sprite-close-green);
          }
        }
        @media #{$mediaMobile} {
          &.active {
            .item-inner {
              h2 {
                color: $green;
              }
            }
          }
        }
      }
      &.grey {
        .text {
          background-color: #b8b9bb;
        }
        .item-inner {
          background-color: #b8b9bb;
          .close {
            &:before,
            &:after {
              background-color: #b8b9bb;
            }
          }
        }
        & + .description {
          border-color: #b8b9bb;
          p {
            &:first-child {
              &:first-letter {
                color: #b8b9bb;
              }
            }
          }
          .close {
            @include sprite($sprite-close-grey);
          }
        }
        @media #{$mediaMobile} {
          &.active {
            .item-inner {
              h2 {
                color: #b8b9bb;
              }
            }
          }
        }
      }

      &.no-image {
        .text {
          height: 100%;
          @media #{$mediaMobile} {
            position: static;
            height: auto;
          }
        }
      }
      @media #{$mediaMobile} {
        width: 100%!important;
        padding: 0;
        height: auto;
      }
    }
  }
}

.add-more {
  margin: 0 auto 40px;
  background: $grey;
  color: #fff;
  text-decoration: none;
  font-size: 31px;
  text-align: center;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  &:hover {
    background: #fff;
    color: $grey;
  }
  @media #{$mediaMobile} {
    font-size: 46px;
    width: 51px;
    height: 51px;
    line-height: 51px;
  }
}