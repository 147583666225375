#footer {

  .menu {
    font-size: 0;
    margin: 0;
    padding: 0;
    @media #{$mediaMobile} {
      width: 100%;
      text-align: center;
    }
    li {
      display: inline-block;
      font-size: 11px;
      line-height: 14px;
      &:after {
        content: '|';
        margin: 0 8px;
      }
      &:last-child {
        &:after {
          content: '';
        }
      }
      a {
        color: inherit;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      @media #{$mediaMobile} {
        font-size: 16px;
        line-height: 18px;
        &:last-child {
          display: block;
        }
        &:nth-last-child(2) {
          &:after {
            content: '';
          }
        }
      }
    }
  }

  .upper-footer {
    border-top: 1px solid $grey;
    padding: 28px 0 35px;
    overflow: hidden;
    @media #{$mediaMobile} {
      padding-top: 0;
    }
    .logo-bottom {
      font-weight: bold;
      color: #3e3d40;
      text-transform: uppercase;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:before {
        content: '';
        @include sprite($sprite-logo-s-n-c-f);
        display: inline-block;
        margin-right: 7px;
      }
      @media #{$mediaMobile} {
        border-bottom: 1px solid $grey;
        display: block;
        width: 100%;
        padding: 15px 12px;
        margin: 0 0 20px;
        font-size: 16px;
      }
    }
    .menu {
      float: right;
      margin-top: 8px;
      @media #{$mediaMobile} {
        float: none;
        margin: 0;
      }
      .mail {
        color: #cb0044;
      }
    }
  }
  .bottom-footer {
    color: #fff;
    background-color: #242424;
    text-align: center;
    padding: 15px 0;
  }
}